<template>
	<a-config-provider :locale="locale">
		<div id="app">
			<component :is="layout">
				<router-view />
			</component>
		</div>
	</a-config-provider>
</template>

<script>
import { ConfigProvider } from 'ant-design-vue';

import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
export default ({
	components: {
    'a-config-provider': ConfigProvider
  },
	computed: {
		layout() {
			return "layout-" + (this.$route.meta.layout || "default").toLowerCase();
		}
	},
	data() {
		return {
			locale: zhCN,
		};
	},
})

</script>

<style lang="scss"></style>